var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('private-template',{scopedSlots:_vm._u([{key:"private-template",fn:function(){return [_c('div',{staticClass:"sp__v__2x"}),_c('div',[_c('HeadingBar',{attrs:{"title":_vm.$i18n.t('cp_request_inbox_title'),"text":_vm.$i18n.t('cp_request_inbox_text',
        { name: _vm.$store.getters['user/getCurrentUser'].name })}}),_c('div',{staticClass:"sp__v__2x"}),_c('div',{staticClass:"tabContainer"},[(!_vm.isDoctor && !_vm.isCollaborator)?_c('div',{staticClass:"buttonContainer"},[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.showCreateView.apply(null, arguments)}}},[_vm._v(" "+_vm._s(("+ " + (_vm.$i18n.t('btn_new_f'))))+" ")])],1):_vm._e(),_c('div',{staticClass:"sp__v__2x"}),_c('b-tabs',{attrs:{"lazy":""}},[_c('b-tab',{attrs:{"title":_vm.counts.request === 0
            ? _vm.$i18n.t('lb_request_tab_table')
            : _vm.$i18n.t('lb_request_tab_table_count',{ count: _vm.counts.request})}},[_c('div',{staticClass:"sp__v__1d5x"}),_c('MyRequests')],1),(_vm.isVidasprime || (_vm.isClinicRole && _vm.isAllowCollaborations))?_c('b-tab',{attrs:{"title":_vm.counts.sent === 0
              ? _vm.$i18n.t('lb_collaboration_tab_sent')
              : _vm.$i18n.t('lb_collaboration_tab_sent_count',{count: _vm.counts.sent})}},[_c('div',{staticClass:"sp__v__1d5x"}),_c('InvitationSent')],1):_vm._e(),(_vm.isVidasprime || (_vm.isClinicRole && _vm.isAllowCollaborations))?_c('b-tab',{attrs:{"title":_vm.counts.received === 0
              ? _vm.$i18n.t('lb_collaboration_tab_received')
              : _vm.$i18n.t('lb_collaboration_tab_received_count',{count: _vm.counts.received})}},[_c('div',{staticClass:"sp__v__1d5x"}),_c('InvitationReceived')],1):_vm._e()],1)],1)],1),_c('CreateRequest',{attrs:{"show":_vm.showCreateRequest,"id":"create-request"},on:{"addRequest":_vm.addRequest,"handleCancel":_vm.closeCreateModal}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }