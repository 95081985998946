<template>
  <private-template>
    <template v-slot:private-template>
      <div class="sp__v__2x"/>
      <div>
        <HeadingBar
          :title="$i18n.t('cp_request_inbox_title')"
          :text="$i18n.t('cp_request_inbox_text',
          { name: $store.getters['user/getCurrentUser'].name })"
        />
        <div class="sp__v__2x"/>
        <div class="tabContainer">
          <div
            v-if="!isDoctor && !isCollaborator"
            class="buttonContainer"
          >
            <b-button
              @click.prevent="showCreateView"
              variant="primary"
              size="sm"
            >
              {{ `+ ${$i18n.t('btn_new_f')}` }}
            </b-button>
          </div>
          <div class="sp__v__2x"/>
          <b-tabs lazy>
            <b-tab :title=" counts.request === 0
              ? $i18n.t('lb_request_tab_table')
              : $i18n.t('lb_request_tab_table_count',{ count: counts.request})
            ">
              <div class="sp__v__1d5x"/>
              <MyRequests />
            </b-tab>
            <b-tab
              :title=" counts.sent === 0
                ? $i18n.t('lb_collaboration_tab_sent')
                : $i18n.t('lb_collaboration_tab_sent_count',{count: counts.sent})
              "
              v-if="isVidasprime || (isClinicRole && isAllowCollaborations)"
            >
              <div class="sp__v__1d5x"/>
              <InvitationSent />
            </b-tab>
            <b-tab
              :title=" counts.received === 0
                ? $i18n.t('lb_collaboration_tab_received')
                : $i18n.t('lb_collaboration_tab_received_count',{count: counts.received})
              "
              v-if="isVidasprime || (isClinicRole && isAllowCollaborations)"
            >
              <div class="sp__v__1d5x"/>
              <InvitationReceived />
            </b-tab>
          </b-tabs>

        </div>
      </div>
        <CreateRequest
          :show="showCreateRequest"
          id="create-request"
          @addRequest="addRequest"
          @handleCancel="closeCreateModal"
        />
    </template>
  </private-template>
</template>

<script>
import PrivateTemplate from '@/components/organisms/PrivateTemplate.vue';
import CreateRequest from '@/components/organisms/Request/CreateRequest.vue';
import HeadingBar from '@/components/molecules/HeadingBar/HeadingBar.vue';
import defaultRequest from '../defaultRequest';

export default {
  data() {
    return {
      request: defaultRequest,
      showCreateRequest: false,
      oldEditedRow: {
        id: null,
        notes: [],
        documents: [],
      },
      counts: {
        request: 0,
        sent: 0,
        received: 0,
      },
    };
  },
  mounted() {
    this.getRequestsNumbers();
  },
  methods: {
    async getRequestsNumbers() {
      const reqResp = await this.$store.dispatch('request/filterRequest', { page: 1, perPage: 1, filters: ['status:pending'] });
      this.counts.request = reqResp.pagination.totalItems;
      if (!this.isDoctor) {
        const sentResp = await this.$store.dispatch('invitations/getInvitations', { page: 1, perPage: 1, filters: ['status:pending', 'type:clinic'] });
        this.counts.sent = sentResp.pagination.totalItems;
        const receivedResp = await this.$store.dispatch('invitations/getInvitations', { page: 1, perPage: 1, filters: ['status:pending', 'type:collaborator'] });
        this.counts.received = receivedResp.pagination.totalItems;
      }
    },
    handleRevertEditedRowRequest() {
      for (let i = 0; i < this.requestList.length; i += 1) {
        if (this.requestList[i].id === this.oldEditedRow.id) {
          this.requestList[i].notes = this.oldEditedRow.notes;
          this.requestList[i].documents = this.oldEditedRow.documents;

          break;
        }
      }

      this.oldEditedRow.id = null;
      this.oldEditedRow.notes = [];
      this.oldEditedRow.documents = [];
    },

    showCreateView() {
      this.showCreateRequest = true;
      this.$root.$emit('bv::toggle::collapse', 'create-request-sidebar');
    },

    closeCreateModal() {
      this.showCreateRequest = false;
    },

    closeCreateModalAfterAction() {
      this.closeCreateModal();
      this.$root.$emit('bv::toggle::collapse', 'create-request-sidebar');
    },

    addData(data) {
      this.requestList.unshift(data);
      const updatedRequestList = this.removeDuplicates(this.requestList[0].documents);
      this.requestList.documents = updatedRequestList;
      this.handleRevertEditedRowRequest();
      this.incrementPagination();
    },

    removeDuplicates(list) {
      return [...new Set(list)];
    },

    clearDocumentsAndNotes() {
      defaultRequest.documents = [];
      defaultRequest.notes = [];
    },

    incrementPagination() {
      this.pagination.totalItems += 1;
      this.pagination.itemsPerPage += 1;
    },

    addRequest(data) {
      this.addData(data);
      this.clearDocumentsAndNotes();
      this.closeCreateModalAfterAction();
    },

  },
  computed: {
    requestList: {
      get() { return this.$store.state.request.request.requestList; },
      set(value) { this.$store.commit('request/setRequestList', value); },
    },
    pagination: {
      get() { return this.$store.state.request.request.pagination; },
      set(value) { this.$store.commit('request/setRequestPagination', value); },
    },
    isVidasprime() {
      return this.$store.getters['user/isVidasprime'];
    },
    isClinicRole() {
      return this.$store.getters['user/isClinicRole'];
    },
    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },
    isCollaborator() {
      return this.$store.getters['user/isCollaborator'];
    },
    isAllowCollaborations() {
      return this.$store.getters['user/isAllowCollaborations'];
    },

  },
  components: {
    MyRequests: () => import('@/components/organisms/Request/tabs/MyRequests.vue'),
    InvitationSent: () => import('@/components/organisms/Request/tabs/InvitationsSent.vue'),
    InvitationReceived: () => import('@/components/organisms/Request/tabs/InvitationsReceived.vue'),
    PrivateTemplate,
    CreateRequest,
    HeadingBar,
  },
};
</script>
<style lang="sass" scoped>
@import './RequestDesktopView.sass'
</style>
